import React from "react";
import { needsPasswordPrompt } from "./src/utils/password";
import PasswordProtect from "./src/components/PasswordProtect";
import Layout from "./src/components/Layout";
import { WrapPageElementBrowserArgs } from "gatsby";
import { wrapLayout } from "./src/utils/layout";

import "./src/style/global.scss";

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs) => {
  const { location } = props;

  if (needsPasswordPrompt(location)) {
    return <PasswordProtect />;
  }

  if (wrapLayout(location)) {
    return (
      <Layout isHome={location.pathname === "/"} {...props}>
        {element}
      </Layout>
    );
  } else {
    element;
  }
};
