import React from "react";
import type { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "./Layout.scss";
import Navbar from "./Navbar";

const Layout: React.FC<{ children?: React.ReactNode; isHome?: boolean }> = ({
  children,
  isHome,
}) => (
  <div className={`layout ${isHome ? "home" : ""}`}>
    <Navbar />
    <div className="margin left">
      <StaticImage
        layout="fullWidth"
        alt=""
        src={"../images/cornfield-left.jpg"}
      />
    </div>
    <main>{children}</main>
    <div className="margin right">
      <StaticImage
        layout="fullWidth"
        alt=""
        src={"../images/cornfield-right.jpg"}
      />
    </div>
  </div>
);

export const Head: HeadFC = () => (
  <title>Casey & Michelle are getting married!</title>
);

export default Layout;
