import { WindowLocation } from "@reach/router";
import Cookies from "js-cookie";
import queryString from "query-string";

const PASSWORD = "SPICEUPYOURLIFE";
const COOKIE_NAME = "session-password";

export function setSessionPassword(passwordCandidate: string) {
  Cookies.set(COOKIE_NAME, passwordCandidate);
  localStorage.setItem(COOKIE_NAME, passwordCandidate);
}

export function needsPasswordPrompt(location: WindowLocation): boolean {
  if (isProtectedPage(location)) {
    const passwordCandidate =
      getQueryPassword(location) || getSessionPassword();
    return !(
      passwordCandidate &&
      passwordCandidate
        .split("")
        .filter((c) => c != " ")
        .join("")
        .toUpperCase() === PASSWORD
    );
  }

  return false;
}

function getSessionPassword() {
  return localStorage.getItem(COOKIE_NAME) || Cookies.get(COOKIE_NAME);
}

function getQueryPassword(location: WindowLocation): string | null {
  const { secret } = queryString.parse(location.search);
  return Array.isArray(secret) ? secret[0] : secret;
}

function isProtectedPage(location: WindowLocation) {
  return matchPath(
    location,
    [
      "/wedding/gallery",
      "/wedding/music",
      "/wedding/schedule",
      "/wedding/story",
      // "/wedding/rsvp",
    ],
    true
  );
}

function matchPath(
  { pathname }: WindowLocation,
  pagePaths: string[],
  partialMatching: boolean
) {
  return pagePaths.find((path) => {
    const isIndexPage = pathname === "/";

    if (partialMatching && !isIndexPage) {
      return pathname.startsWith(path);
    }

    return path === pathname;
  });
}
