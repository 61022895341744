import React, { useState } from "react";
import { Link } from "gatsby";

import "./Navbar.scss";

const links = [
  "Schedule",
  "Accomodations",
  /*{
    label: "Our Story",
    to: `/wedding/story`,
  },*/
  "Music",
  "Gallery",
  {
    label: "Registry",
    to: `https://withjoy.com/casey-and-michelle/registry`,
  },
  // "FAQs",
].map((l) =>
  typeof l === "string"
    ? {
        label: l,
        to: `/wedding/${l.toLowerCase()}`,
      }
    : l
);

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <nav className={["top-nav", ...(navbarOpen ? ["open"] : [])].join(" ")}>
      <Link to="/" onClick={() => setNavbarOpen(false)}>
        <div className="icon">
          <span className="c">C</span>
          <span className="a">&</span>
          <span className="m">M</span>
        </div>
      </Link>
      <div
        onClick={() => setNavbarOpen(!navbarOpen)}
        className={`hamburger hamburger--spin js-hamburger ${
          navbarOpen ? "is-active" : ""
        }`}
      >
        <div className="hamburger-box">
          <div className="hamburger-inner"></div>
        </div>
      </div>
      <div className="navbox">
        {links.map((link) =>
          link.to.startsWith("http") ? (
            <a key={link.to} href={link.to} target="_blank">
              {link.label}
            </a>
          ) : (
            <Link
              key={link.to}
              to={link.to}
              activeClassName="active"
              onClick={() => setNavbarOpen(false)}
            >
              {link.label}
            </Link>
          )
        )}
      </div>
    </nav>
  );
};

export default Navbar;
