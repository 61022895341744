exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wedding-accomodations-mdx": () => import("./../../../src/pages/wedding/accomodations.mdx" /* webpackChunkName: "component---src-pages-wedding-accomodations-mdx" */),
  "component---src-pages-wedding-faqs-mdx": () => import("./../../../src/pages/wedding/faqs.mdx" /* webpackChunkName: "component---src-pages-wedding-faqs-mdx" */),
  "component---src-pages-wedding-gallery-mdx": () => import("./../../../src/pages/wedding/gallery.mdx" /* webpackChunkName: "component---src-pages-wedding-gallery-mdx" */),
  "component---src-pages-wedding-index-mdx": () => import("./../../../src/pages/wedding/index.mdx" /* webpackChunkName: "component---src-pages-wedding-index-mdx" */),
  "component---src-pages-wedding-invitation-tsx": () => import("./../../../src/pages/wedding/invitation.tsx" /* webpackChunkName: "component---src-pages-wedding-invitation-tsx" */),
  "component---src-pages-wedding-music-tsx": () => import("./../../../src/pages/wedding/music.tsx" /* webpackChunkName: "component---src-pages-wedding-music-tsx" */),
  "component---src-pages-wedding-registry-mdx": () => import("./../../../src/pages/wedding/registry.mdx" /* webpackChunkName: "component---src-pages-wedding-registry-mdx" */),
  "component---src-pages-wedding-rsvp-tsx": () => import("./../../../src/pages/wedding/rsvp.tsx" /* webpackChunkName: "component---src-pages-wedding-rsvp-tsx" */),
  "component---src-pages-wedding-schedule-mdx": () => import("./../../../src/pages/wedding/schedule.mdx" /* webpackChunkName: "component---src-pages-wedding-schedule-mdx" */),
  "component---src-pages-wedding-story-mdx": () => import("./../../../src/pages/wedding/story.mdx" /* webpackChunkName: "component---src-pages-wedding-story-mdx" */)
}

